<template>
  <div>
    <div
      v-if="loading"
      class="w-full flex flex-row justify-center text-secondary gap-4"
    >
      <a-icon class="text-xl" type="loading" />
      <p>Cargando</p>
    </div>
    <HeaderAnalytics :loading="loading" />
    <div v-if="analytics && !loading" class="my-8">
      <h2 class="mb-5 text-left font-bold text-2xl text-primary">General</h2>
      <GeneralStats :stats="analytics.QUEJAS_GENERALES" />
    </div>
    <div
      v-if="analytics && !loading"
      class="w-full mt-8 grid grid-cols-1 gap-5"
    >
      <h2 class="text-left font-bold text-2xl text-primary">Meses</h2>
      <div class="relative p-10 bg-white rounded-lg shadow-lg">
        <canvas id="bar-chart"></canvas>
      </div>
    </div>
    <div v-if="analytics && !loading" class="my-8">
      <h2 class="mb-5 text-left font-bold text-2xl text-primary">Productos</h2>
      <div class="bg-white rounded-lg shadow-lg overflow-hidden">
        <ProductTable :products="analytics.QUEJAS_POR_PRODUCTO" />
      </div>
    </div>
    <div v-if="analytics && !loading" class="my-8">
      <h2 class="mb-5 text-left font-bold text-2xl text-primary">Motivos</h2>
      <div class="bg-white rounded-lg shadow-lg overflow-hidden">
        <ReasonTable :reasons="analytics.QUEJAS_POR_MOTIVO" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import HeaderAnalytics from '@/components/Dashboard/Analytics/HeaderAnalytics'
import ProductTable from '@/components/Dashboard/Analytics/ProductTable'
import ReasonTable from '@/components/Dashboard/Analytics/ReasonTable'
import GeneralStats from '@/components/Dashboard/Analytics/GeneralStats'
export default {
  components: {
    ProductTable,
    ReasonTable,
    GeneralStats,
    HeaderAnalytics,
  },
  data() {
    return {
      loading: false,
      analytics: null,
      monthChart: null,
      showMonthChart: false,
    }
  },
  watch: {
    '$route.query'(newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchAnalytics()
      }
    },
  },
  async created() {
    this.fetchAnalytics()
  },
  methods: {
    async fetchAnalytics() {
      const params = { ...this.$route.query }
      this.loading = true
      const response = await this.$api.analytics.getAnalytics(params)
      this.loading = false
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      this.analytics = response.data
      this.loadChart()
    },
    async loadChart() {
      if (this.monthChart) {
        this.monthChart.destroy()
      }
      Chart.register(...registerables)
      setTimeout(() => {
        const ctx2 = document.getElementById('bar-chart')
        this.monthChart = new Chart(ctx2, {
          type: 'bar',
          data: this.analytics.QUEJAS_POR_MES,
        })
      }, 300)
    },
  },
}
</script>

<style></style>
