<template>
  <div v-if="stats" class="grid grid-cols-3 gap-3">
    <span
      v-for="(analytic, index) in stats"
      :key="index"
      class="p-5 flex items-center gap-3 rounded-lg bg-white shadow-lg"
    >
      <div
        class="relative flex items-center justify-center p-6 h-10 w-10 rounded-full text-xl overflow-hidden"
      >
        <div class="absolute h-full w-full opacity-10 bg-secondary"></div>
        <a-icon type="bar-chart" />
      </div>
      <div class="flex-col text-left">
        <h4 class="text-gray-500">{{ analytic.nombre }}</h4>
        <b class="text-xl"
          >{{ analytic.valor }}
          {{ analytic.porcentaje ? `(${analytic.porcentaje})` : '' }}</b
        >
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style></style>
