<template>
  <div>
    <a-modal
      :visible="showModal"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :footer="null"
    >
      <div class="flex flex-col py-4 gap-6">
        <h3 class="m-0 text-lg font-bold">Filtros</h3>
        <div class="-mt-8 grid grid-cols-1 md:grid-cols-2 gap-3">
          <BaseDate
            type="text"
            label="Fecha inicio"
            v-model="query.start_date"
          />
          <BaseDate type="text" label="Fecha final" v-model="query.end_date" />
        </div>
        <p v-if="feedback" class="text-center text-red-500">{{ feedback }}</p>
        <div class="flex flex-col-reverse md:flex-row gap-5">
          <Button
            :danger="true"
            :block="true"
            :invert="true"
            @click.native="handleCancel"
          >
            Cancelar
          </Button>
          <Button styles="secondary" :block="true" @click.native="handleOk">
            Aplicar
          </Button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const querySchema = {
  start_date: undefined,
  end_date: undefined,
}
export default {
  props: ['showModal'],
  data() {
    return {
      confirmLoading: false,
      query: { ...querySchema },
      feedback: '',
    }
  },
  watch: {
    '$route.query'(newValue, oldValue) {
      if (newValue != oldValue) {
        this.query = {
          start_date: newValue.start_date || undefined,
          end_date: newValue.end_date || undefined,
        }
      }
    },
  },
  methods: {
    handleOk() {
      if (this.invalidForm()) return
      this.setQuery()
      this.confirmLoading = true
      this.query = { ...querySchema }
      this.$emit('closeModal')
    },
    handleCancel() {
      this.query = { ...querySchema }
      this.$emit('closeModal')
      this.visible = false
    },
    setQuery() {
      this.$router.push({ query: this.query })
    },
    invalidForm() {
      this.feedback = null
      if (!this.query.start_date) {
        this.feedback = 'Debe ingresar una fecha de inicio'
        return true
      }

      if (
        this.query.end_date &&
        this.$DateTime.fromISO(this.query.start_date) >
          this.$DateTime.fromISO(this.query.end_date)
      ) {
        this.feedback = 'La fecha final debe ser posterior a la fecha de inicio'
        return true
      }
      return false
    },
  },
}
</script>

<style></style>
