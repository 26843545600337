<template>
  <a-table
    :data-source="reasons"
    :pagination="false"
    :rowKey="(record, index) => index"
  >
    <a-table-column key="name" title="Motivo" data-index="name">
      <template slot-scope="text, record">
        {{ record.name }}
      </template>
    </a-table-column>
    <a-table-column
      key="QUEJAS_RESUELTAS"
      title="Quejas resueltas"
      data-index="QUEJAS_RESUELTAS"
    >
      <template slot-scope="text, record">
        {{ record.QUEJAS_RESUELTAS }}
      </template>
    </a-table-column>
    <a-table-column
      key="TOTAL_QUEJAS"
      title="Total de quejas"
      data-index="TOTAL_QUEJAS"
    >
      <template slot-scope="text, record">
        {{ record.TOTAL_QUEJAS }}
      </template>
    </a-table-column>
    <a-table-column
      key="porcentaje"
      title="Porcentaje de quejas completadas"
      data-index="porcentaje"
    >
      <template slot-scope="text, record">
        {{ record.porcentaje }}
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
export default {
  props: {
    reasons: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style></style>
