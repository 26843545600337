<template>
  <div v-if="!loading" class="mb-5 flex flex-col items-start">
    <div
      class="w-full flex flex-col items-center justify-center gap-2 sm:flex-row sm:justify-start"
    >
      <Button class="" @click.native="toggleFilterModal"
        ><a-icon type="search" /> Filtros de búsqueda
      </Button>
      <Button v-if="isFiltered" :danger="true" @click.native="deleteFilters"
        ><a-icon type="delete" />Eliminar filtros
      </Button>
      <Button
        styles="secondary"
        :invert="true"
        class="h-10 w-48 mt-auto"
        @click.native="downloadAnalytics"
        :loading="loadingAnalytics"
        :disable="loadingAnalytics"
      >
        <a-icon v-if="!loadingAnalytics" type="download" />Descargar analítica
      </Button>
    </div>
    <div class="mt-3">
      <div class="flex gap-2">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="relative text-white"
        >
          <span v-if="filter" class="block"
            ><div
              class="absolute h-full w-full rounded-full opacity-10 bg-secondary"
            ></div>
            <p class="py-px px-4 text-primary">{{ filter }}</p></span
          >
        </div>
      </div>
    </div>
    <ModalFilters
      :showModal="showFilterModal"
      @closeModal="toggleFilterModal"
    />
  </div>
</template>

<script>
import ModalFilters from '@/components/Dashboard/Analytics/ModalFilters'
export default {
  props: ['loading'],
  components: {
    ModalFilters,
  },
  data() {
    return {
      loadingAnalytics: false,
      showFilterModal: false,
      filters: {},
    }
  },
  computed: {
    isFiltered() {
      const query = this.$route.query
      for (const q in query) {
        if (query[q]) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    '$route.query'(newValue, oldValue) {
      if (newValue != oldValue) {
        this.filters = {
          start_date: newValue.start_date
            ? `Fecha inicio: ${newValue.start_date}`
            : undefined,
          end_date: newValue.end_date
            ? `Fecha final: ${newValue.end_date} `
            : undefined,
        }
      }
    },
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal
    },
    deleteFilters() {
      this.$router.push({ query: {} })
    },
    async downloadAnalytics() {
      this.loadingAnalytics = true
      let response = await this.$api.analytics.downloadAnalytics()
      this.loadingAnalytics = false
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      let link = document.createElement('a')
      link.setAttribute('href', response.data.download_url)
      link.setAttribute('download', 'lista_quejas.xls')
      link.click()
    },
  },
}
</script>

<style></style>
